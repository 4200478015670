<template>
  <v-container>
    <span class="float-right mt-n5 mb-n10">
      <v-tooltip v-if="!$vuetify.theme.dark" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="info" x-small fab @click="darkMode">
            <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
          </v-btn>
        </template>
        <span>Dark Mode On</span>
      </v-tooltip>

      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="info" x-small fab @click="darkMode">
            <v-icon color="orange">mdi-white-balance-sunny</v-icon>
          </v-btn>
        </template>
        <span>Dark Mode Off</span>
      </v-tooltip>
    </span>
  </v-container>
</template>

<script>
export default {
  name: "DarkLightSwitch",
  data: () => ({}),
  mounted() {
    this.$vuetify.theme.dark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style></style>

<template>
  <welcome-card />
</template>

<script>
import WelcomeCard from "../components/display/WelcomeCard";

export default {
  name: "Welcome",

  components: {
    WelcomeCard,
  },
};
</script>
